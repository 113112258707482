@media print {
  /*
   * Hides URLs from links when printed
   */
  a[href]:after {
    content: none !important;
  }

  body {
    padding-top:0 !important;
    font-size:10px !important;
  }
}

@import "bootstrap.less";
@import "theme.less";
@import "font-awesome.less";

// DataTables helpers
.DTTT {margin-bottom: 1em;}
div.dataTables_wrapper {clear: both;}

// Pickadate helper
.picker__input[readonly],
.picker__input:hover {
  background: #fff;
  cursor: text;
}
